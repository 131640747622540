<template>
  <section class="descr-list">
    <div class="container">
      <div class="descr-list__inner">
        <p class="descr-list__text">
          Украинская служба эксплуатации «Технологический Экспертный Центр» обслуживает морские<br> и речные
          гидротехнические сооружения, требующие регулярной подводной инспекции.<br>
        </p>
        <ul class="descr-list__list">
          <li class="descr-list__item">
            судоходные оградительные сооружения, защищающие акватории портов и аванпортов
          </li>
          <li class="descr-list__item">
            причальные сооружения
          </li>
          <li class="descr-list__item">
            берегоукрепительные и ограждающие транспортные сооружения
          </li>
          <li class="descr-list__item">
            судоспускные и судоподъемные сооружения
          </li>
          <li class="descr-list__item">
            подходные каналы для морских судов в акватории портов
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
export default {
    name: 'BuildingsList'
}
</script>
